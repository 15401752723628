import React, { useEffect, useState } from "react";
import axios from "axios";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

const getApiUrl = (path) => {
  const hostname = window.location.hostname;
  if (hostname.includes("testgrouple.com") || hostname.includes("localhost")) {
    return `https://server.testgrouple.com${path}`;
  }
  if (hostname.includes("grouple.tech")) {
    return `https://production.grouple.tech${path}`;
  }
  throw new Error("Unknown hostname");
};

export default function ClientTable({ headers, searchQuery }) {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const clientsPerPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = getApiUrl("/api/v2/merchant/customer/getCustomerData");
        const response = await axios.get(url, {
          withCredentials: true,
        });

        if (response.status === 200) {
          setClients(response.data || []);
        } else {
          setError(`Error: ${response.status}`);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const filteredClients = clients.data.filter((client) => {
    const fullName =
      `${client.user_firstName} ${client.user_lastName}`.toLowerCase();
    return (
      fullName.includes(searchQuery.toLowerCase()) ||
      client.user_email.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const indexOfLastClient = currentPage * clientsPerPage;
  const indexOfFirstClient = indexOfLastClient - clientsPerPage;
  const currentClients = filteredClients.slice(
    indexOfFirstClient,
    indexOfLastClient
  );

  const totalClients = clients.data.length;
  const totalPages = Math.ceil(totalClients / clientsPerPage);

  const renderPagination = () => {
    const pagination = [];
    const showEllipsisStart = currentPage > 3;
    const showEllipsisEnd = currentPage < totalPages - 2;

    pagination.push(
      <button
        key={1}
        onClick={() => setCurrentPage(1)}
        className={`relative inline-flex items-center rounded-md px-4 py-2 text-sm font-medium ${
          currentPage === 1
            ? "bg-groupleBlue text-white"
            : "bg-white text-gray-500 hover:bg-gray-50"
        }`}
      >
        1
      </button>
    );

    if (showEllipsisStart) {
      pagination.push(
        <span
          key="ellipsis-start"
          className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500"
        >
          ...
        </span>
      );
    }

    for (
      let i = Math.max(2, currentPage - 1);
      i <= Math.min(totalPages - 1, currentPage + 1);
      i++
    ) {
      pagination.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={`relative inline-flex items-center rounded-md px-4 py-2 text-sm font-medium ${
            currentPage === i
              ? "bg-[#4B68FE] text-white"
              : "bg-white text-gray-500 hover:bg-gray-50"
          }`}
        >
          {i}
        </button>
      );
    }

    if (showEllipsisEnd) {
      pagination.push(
        <span
          key="ellipsis-end"
          className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500"
        >
          ...
        </span>
      );
    }

    if (totalPages > 1) {
      pagination.push(
        <button
          key={totalPages}
          onClick={() => setCurrentPage(totalPages)}
          className={`relative inline-flex items-center rounded-md px-4 py-2 text-sm font-medium ${
            currentPage === totalPages
              ? "bg-[#4B68FE] text-white"
              : "bg-white text-gray-500 hover:bg-gray-50"
          }`}
        >
          {totalPages}
        </button>
      );
    }

    return pagination;
  };

  const handleRowClick = async (customerCode) => {
    try {
      const url = getApiUrl(
        "/api/v2/merchant/customer/getCustomerDataByCustomerCode"
      );
      const response = await axios({
        url,
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: { customer_code: customerCode },
        withCredentials: true,
      });
      navigate("/ClientsDetail", { state: { client: response.data } });
    } catch (error) {
      console.error("Error fetching customer data:", error.response);
    }
  };

  return (
    <div className="overflow-hidden border border-gray-300 rounded-tr-xl rounded-tl-xl h-fit rounded-br-xl rounded-bl-xl">
      <table className="min-w-full divide-y divide-gray-300 table-fixed">
        <thead className="bg-gray-50">
          <tr>
            {headers.map((header, index) => (
              <th
                key={index}
                scope="col"
                className="py-2.5 pl-4 text-left text-sm font-normal text-gray-500 sm:pl-6"
              >
                {header}
              </th>
            ))}
            <th className="py-2.5 pl-4 text-left text-sm font-normal text-gray-500 sm:pl-6"></th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white text-left">
          {currentClients.map((client) => {
            const totalCost = Math.floor(client.bookingTotalCost);
            const averageSpend =
              client.bookingCount > 0
                ? Math.floor(totalCost / client.bookingCount)
                : 0;

            return (
              <tr
                key={client.email}
                onClick={() => {
                  handleRowClick(client.beta_reservation_user_details_code);
                }}
                className="cursor-pointer hover:bg-gray-100"
              >
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  {client.user_firstName} {client.user_lastName}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {client.user_InternationalCode} {client.user_phone}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {client.user_email}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {client.bookingCount}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {client.bookingCurrency}{" "}
                  {new Intl.NumberFormat().format(totalCost)}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {client.bookingCurrency}{" "}
                  {new Intl.NumberFormat().format(averageSpend)}
                </td>

                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <a href="2" className="text-indigo-600 hover:text-indigo-900">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M5 11.25C5.33152 11.25 5.64946 11.1183 5.88388 10.8839C6.1183 10.6495 6.25 10.3315 6.25 10C6.25 9.66848 6.1183 9.35054 5.88388 9.11612C5.64946 8.8817 5.33152 8.75 5 8.75C4.66848 8.75 4.35054 8.8817 4.11612 9.11612C3.8817 9.35054 3.75 9.66848 3.75 10C3.75 10.3315 3.8817 10.6495 4.11612 10.8839C4.35054 11.1183 4.66848 11.25 5 11.25Z"
                        fill="#242426"
                      />
                      <path
                        d="M10 11.25C10.3315 11.25 10.6495 11.1183 10.8839 10.8839C11.1183 10.6495 11.25 10.3315 11.25 10C11.25 9.66848 11.1183 9.35054 10.8839 9.11612C10.6495 8.8817 10.3315 8.75 10 8.75C9.66848 8.75 9.35054 8.8817 9.11612 9.11612C8.8817 9.35054 8.75 9.66848 8.75 10C8.75 10.3315 8.8817 10.6495 9.11612 10.8839C9.35054 11.1183 9.66848 11.25 10 11.25Z"
                        fill="#242426"
                      />
                      <path
                        d="M15 11.25C15.3315 11.25 15.6495 11.1183 15.8839 10.8839C16.1183 10.6495 16.25 10.3315 16.25 10C16.25 9.66848 16.1183 9.35054 15.8839 9.11612C15.6495 8.8817 15.3315 8.75 15 8.75C14.6685 8.75 14.3505 8.8817 14.1161 9.11612C13.8817 9.35054 13.75 9.66848 13.75 10C13.75 10.3315 13.8817 10.6495 14.1161 10.8839C14.3505 11.1183 14.6685 11.25 15 11.25Z"
                        fill="#242426"
                      />
                    </svg>
                  </a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* pagination  */}
      <div className="flex items-center justify-between px-4 py-2 sm:px-6 bg-white border-b-0 border-gray-300 border rounded-br-xl rounded-bl-xl h-fit">
        <div className="text-sm text-gray-500">
          Showing {indexOfFirstClient + 1} to{" "}
          {Math.min(indexOfLastClient, totalClients)} of {totalClients} results
        </div>
        <div className="flex">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            className="mr-2 inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white rounded-md hover:bg-gray-50"
            disabled={currentPage === 1}
          >
            <ChevronLeftIcon className="h-5 w-5" />
          </button>
          {renderPagination()}
          <button
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
            className="ml-2 inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white rounded-md hover:bg-gray-50"
            disabled={currentPage === totalPages}
          >
            <ChevronRightIcon className="h-5 w-5" />
          </button>
        </div>
      </div>
    </div>
  );
}
