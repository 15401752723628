import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { Breadcrumb } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import "../Styles/BookingDetails.css";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import { useEstateCode } from "./EstateCodeContext";
import { DatePicker } from "rsuite";
import { Select } from "antd";

const getApiUrl = (path) => {
  const hostname = window.location.hostname;
  if (hostname.includes("testgrouple.com") || hostname.includes("localhost")) {
    return `https://server.testgrouple.com${path}`;
  }
  if (hostname.includes("grouple.tech")) {
    return `https://production.grouple.tech${path}`;
  }
  throw new Error("Unknown hostname");
};

const BookingsDetails = () => {
  const location = useLocation();
  const {
    booking,
    spocName,
    currentSection,
    selectedSection,
    tabsState,
    client,
  } = location.state;

  console.log(booking);
  const navigate = useNavigate();
  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedGuest, setSelectedGuest] = useState(null);
  const handleRowClick = (guest) => {
    setSelectedGuest(guest);
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
    setSelectedGuest(null);
  };

  const [tabs, setTabs] = useState([
    { name: "Details", current: true },
    { name: "Food Menu", count: 0, current: false },
    { name: "Beverage Menu", count: 0, current: false },
    { name: "Add Ons", count: 0, current: false },
    { name: "Payment", count: 0, current: false },
    { name: "Guest list", count: 0, current: false },
  ]);

  const [tabs2, setTabs2] = useState([
    { name: "Food menu", current: true },
    { name: "Beverage menu", count: 0, current: false },
    { name: "Add ons", count: 0, current: false },
  ]);

  const handleNavItemClick = (itemName) => {
    const updatedTabs = tabs.map((item) => ({
      ...item,
      current: item.name === itemName,
    }));
    setTabs(updatedTabs);
  };

  const handleNavItemClick2 = (itemName) => {
    const updatedTabs2 = tabs2.map((item) => ({
      ...item,
      current: item.name === itemName,
    }));
    setTabs2(updatedTabs2);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  // Table Function
  const headers = ["Guest name", "Phone Number", "Food & Beverage Menu"];

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const [complimentaryNotes, setComplimentaryNotes] = useState(
    booking?.length > 0 ? booking?.bookingComplimentaryNotes || "" : ""
  );
  const [notesAdded, setNotesAdded] = useState(false);

  useEffect(() => {
    if (complimentaryNotes.trim() !== "") {
      setNotesAdded(true);
    } else {
      setNotesAdded(false);
    }
  }, [complimentaryNotes]);

  const handleNotesChange = (e) => {
    setComplimentaryNotes(e.target.value);
    setIsDirty(true);
  };

  const invoiceDetails = booking?.invoiceDetails
    ? JSON.parse(booking?.invoiceDetails)
    : {};

  const [numberOfGuests, setNumberOfGuests] = useState(
    booking?.bookingTotalGuest || ""
  );
  const [formData, setFormData] = useState({
    bookerFirstName: booking?.bookerFirstName || "",
    bookerLastName: booking?.bookerLastName || "",
    bookerEmail: booking?.bookerEmail || "",
    bookerPhone: booking?.bookerPhone || "",
    bookingDate: formatDate(new Date(booking?.bookingDate)) || "",
    bookingSlotStartTime: booking?.bookingSlotStartTime || "",
    bookingSlotEndTime: booking?.bookingSlotEndTime || "",
    bookingTotalGuest: numberOfGuests || "",
    bookingTotalGuestIndividual:
      booking?.bookingPackageCategory === "À la Carte"
        ? booking?.bookingTotalGuest || ""
        : "",
    bookingNotes: booking?.bookingNotes || "",
    bookingPackageCategory: booking?.bookingPackageCategory || "",
    bookingPackage: booking?.bookingPackage || "",
    bookingGrouplePackageCategory: booking?.bookingGrouplePackageCategory || "",
    bookerCompanyName: invoiceDetails.company_name || "",
    bookerCity: invoiceDetails.city || "",
    bookerPostalCode: invoiceDetails.postal_code || "",
    bookerCountry: invoiceDetails.country || "",
    bookerVATNumber: invoiceDetails.vat_no || "",
    bookerAddress: invoiceDetails.address || "",
    bookerState: invoiceDetails.state || "",
  });

  const [isDirty, setIsDirty] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const renderStatus = () => {
    switch (booking.record_status) {
      case 0:
        return (
          <div
            className="bg-pendingBG rounded-3xl py-1 pl-4 pr-3 flex gap-1"
            style={{ alignItems: "center" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="5"
              height="6"
              viewBox="0 0 5 6"
              fill="none"
            >
              <circle cx="2.5" cy="3" r="2.5" fill="#FF9800" />
            </svg>
            <span className="text-pendingText">Pending</span>
          </div>
        );
      case 1:
        return (
          <div
            className="bg-approvedBG rounded-3xl py-1 pl-4 pr-3 flex gap-1"
            style={{ alignItems: "center" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="5"
              height="6"
              viewBox="0 0 5 6"
              fill="none"
            >
              <circle cx="2.5" cy="3" r="2.5" fill="#009600" />
            </svg>
            <span className="text-approvedText">Upcoming</span>
          </div>
        );
      case 3:
        return (
          <div
            className="bg-checkOutBG rounded-3xl py-1 pl-4 pr-3 flex gap-1"
            style={{ alignItems: "center" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="5"
              height="6"
              viewBox="0 0 5 6"
              fill="none"
            >
              <circle cx="2.5" cy="3" r="2.5" fill="#077E8C" />
            </svg>
            <span className="text-checkOutText">Completed</span>
          </div>
        );
      case 4:
        return (
          <div
            className="bg-checkInBG rounded-3xl py-1 pl-4 pr-3 flex gap-1"
            style={{ alignItems: "center" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="5"
              height="6"
              viewBox="0 0 5 6"
              fill="none"
            >
              <circle cx="2.5" cy="3" r="2.5" fill="#7389DE" />
            </svg>
            <span className="text-checkInText">Ongoing</span>
          </div>
        );
      case 2:
        return (
          <div
            className="bg-cancelledBG rounded-3xl py-1 pl-4 pr-3 flex gap-1"
            style={{ alignItems: "center" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="5"
              height="6"
              viewBox="0 0 5 6"
              fill="none"
            >
              <circle cx="2.5" cy="3" r="2.5" fill="#F44336" />
            </svg>
            <span className="text-cancelledText">Cancelled</span>
          </div>
        );
      default:
        return null;
    }
  };
  let bookingTotalGuest;
  if (booking?.bookingPackageCategory === "Group Experiences") {
    bookingTotalGuest = formData.bookingTotalGuest;
  } else if (booking?.bookingPackageCategory === "À la Carte") {
    bookingTotalGuest = formData.bookingTotalGuestIndividual;
  }

  const [cancelClicked, setCancelClicked] = useState(false);

  const handleCancelClick = async () => {
    if (!cancelClicked) {
      setCancelClicked(true);
    } else {
      const reservationId = booking.reservation_code;

      try {
        const url = getApiUrl("/api/v1/merchant/bookings/changeBookingStatus");
        const response = await axios.post(
          url,
          {
            reservation_id: reservationId,
            record_status: 2,
          },
          {
            withCredentials: true,
          }
        );

        if (response.status === 200) {
          navigate("/bookings", {
            state: {
              selectedSection,
            },
          });
        } else {
          console.error("Cancel request failed");
        }
      } catch (error) {
        console.error("Error occurred while making the cancel request:", error);
      }
      setCancelClicked(false);
    }
  };

  const { estateCode } = useEstateCode();
  const [spoc, setSpoc] = useState([]);
  const [selectedSpoc, setSelectedSpoc] = useState(
    spocName.spocCode || "Assign"
  );

  const fetchSpoc = async () => {
    try {
      const url = getApiUrl(`/api/v1/merchant/bookings/getSPOC/${estateCode}`);
      const response = await axios.get(url, { withCredentials: true });
      setSpoc(response.data.data);
    } catch (error) {
      console.error("Error fetching member details:", error);
    }
  };

  useEffect(() => {
    fetchSpoc();
  }, [estateCode]);

  const spocOptions = spoc.map((spocItem) => ({
    value: spocItem.merchant_user_code,
    label: spocItem.merchant_user_name,
  }));

  const [spocError, setSpocError] = useState("");
  const [isSpocSelected, setIsSpocSelected] = useState(false);
  const handleSPOCNAMEChange = (value) => {
    setSelectedSpoc(value);
    setIsSpocSelected(value !== null);
    setIsDirty(true);
    setSpocError("");
  };

  const handleButtonClick = async (event) => {
    event.preventDefault();
    const buttonText = event.target.innerText;
    const reservationId = booking.reservation_code;

    if (!isSpocSelected && buttonText === "Approve") {
      setSpocError("Please assign a SPOC");
      return;
    }

    switch (buttonText) {
      case "Cancel":
        handleCancelClick();
        break;
      case "Approve":
        try {
          const url = getApiUrl(
            `/api/v1/merchant/bookings/changeBookingStatus/${selectedSpoc}`
          );
          const response = await axios.post(
            url,
            {
              reservation_id: reservationId,
              record_status: 5,
            },
            {
              withCredentials: true,
            }
          );
          console.log(response);
          if (response.status === 200) {
            console.log("Approved request successful");
            navigate("/bookings", {
              state: {
                selectedSection,
              },
            });
          } else {
            console.error("Approved request failed");
          }
          console.log(response);
        } catch (error) {
          console.error(
            "Error occurred while making the approved request:",
            error
          );
        }
        break;
      case "Save Reservation":
        if (statusChanged) {
          try {
            const url = getApiUrl(
              "/api/v1/merchant/bookings/changeBookingStatus"
            );
            const response = await axios.post(
              url,
              {
                reservation_id: reservationId,
                record_status: selectedStatusTwo,
              },
              {
                withCredentials: true,
              }
            );

            if (response.status === 200) {
              console.log("Approved request successful");
              navigate("/bookings", {
                state: {
                  selectedSection,
                },
              });
              setStatusChanged(false);
            } else {
              console.error("Approved request failed");
            }
            console.log(response);
          } catch (error) {
            console.error(
              "Error occurred while making the approved request:",
              error
            );
          }
        } else if (notesAdded) {
          try {
            const url = getApiUrl(
              "/api/v1/merchant/bookings/editComplimentaryNotes"
            );
            const response = await axios.post(
              url,
              {
                reservation_id: reservationId,
                bookingComplimentaryNotes: complimentaryNotes,
              },
              {
                withCredentials: true,
              }
            );

            if (response.status === 200) {
              navigate("/bookings", {
                state: {
                  selectedSection,
                },
              });
              setNotesAdded(false);
              setStatusChanged(false);
            } else {
              console.error("Failed to add Notes");
            }
            console.log(response);
          } catch (error) {
            console.error("Error occurred while adding notes:", error);
          }
        } else {
          const convertTo24Hour = (time12h) => {
            const [time, modifier] = time12h.split(" ");
            let [hours, minutes] = time.split(":");
            hours = parseInt(hours);
            minutes = parseInt(minutes);
            if (hours === 12 && modifier === "AM") hours = 0;
            if (modifier === "PM" && hours < 12) hours += 12;

            hours = hours.toString().padStart(2, "0");
            minutes = minutes.toString().padStart(2, "0");
            return `${hours}:${minutes}`;
          };

          let startTime, endTime;
          if (booking?.bookingPackageCategory === "À la Carte") {
            startTime = convertTo24Hour(
              selectedIndividualTimeSlot || formData.bookingSlotStartTime
            );
            endTime = startTime;
          } else {
            startTime = convertTo24Hour(formData.bookingSlotStartTime);
            endTime = convertTo24Hour(formData.bookingSlotEndTime);
          }

          const sendData = {
            bookerFirstName: formData.bookerFirstName,
            bookerLastName: formData.bookerLastName,
            bookerEmail: formData.bookerEmail,
            bookerPhone: formData.bookerPhone,
            bookingDate: formData.bookingDate,
            bookingSlotStartTime: startTime,
            bookingSlotEndTime: endTime,
            bookingTotalGuest: numberOfGuests,
            gross_booking_amt: grandTotal,
            total_addon_amt: addOnAmount,
            total_booking_amt: price,
            total_coupon_amt: booking.total_coupon_amt,
            total_discount_amt: moneyToBeSaved,
            total_tax_amt: serviceFee,
            bookingNotes: formData.bookingNotes,
            bookerInternationalCode: booking.bookerInternationalCode,
            reservation_id: booking.reservation_code,
            spocID: selectedSpoc || "",
            bookingPerPersonCost: parseFloat(
              booking?.bookingPerPersonCostWithoutDiscount.replace(/,/g, "")
            ),
            bookingTotalCost: grandTotal,
            bookingDiscountedAmount: moneyToBeSaved,
          };

          console.log("data", sendData);
          try {
            const url = getApiUrl("/api/v1/merchant/bookings/editBooking");
            const response = await axios.post(url, sendData, {
              withCredentials: true,
            });
            console.log("Data updated successfully:", response.data);
            navigate("/bookings", {
              state: {
                selectedSection,
              },
            });
          } catch (error) {
            console.error("Error occurred while updating data:", error);
          }
        }

        break;

      default:
        console.log("Unknown button clicked");
    }
  };

  const [isTimeSlotOpen, setIsTimeSlotOpen] = useState(false);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [currentDay, setCurrentDay] = useState(null);

  const getCurrentDay = (selectedDate) => {
    if (!selectedDate) return null;
    const dateObj = new Date(selectedDate);
    if (isNaN(dateObj.getTime())) {
      console.error("Invalid date:", selectedDate);
      return null;
    }
    return dateObj
      .toLocaleDateString("en-US", { weekday: "short" })
      .toLowerCase();
  };

  useEffect(() => {
    if (formData.bookingDate) {
      setCurrentDay(getCurrentDay(formData.bookingDate));
    } else {
      setCurrentDay(null);
    }
  }, [formData.bookingDate]);

  const filteredTimeSlots =
    currentDay && booking?.packageTimeslots
      ? booking.packageTimeslots
          .filter((slot) => slot.package_day === currentDay)
          .map((slot) => ({
            value: `${slot.package_min_time_slot} - ${slot.package_max_time_slot}`,
            label: `${new Date(
              `1970-01-01T${slot.package_min_time_slot}`
            ).toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })} - ${new Date(
              `1970-01-01T${slot.package_max_time_slot}`
            ).toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })}`,
          }))
      : [];

  const handleSelectedTimeSlot = (timeSlotValue) => {
    setSelectedTimeSlot(timeSlotValue);

    const [startTime, endTime] = timeSlotValue.split(" - ");

    setFormData((prevFormData) => ({
      ...prevFormData,
      bookingSlotStartTime: startTime,
      bookingSlotEndTime: endTime,
    }));

    setIsDirty(true);
    setIsTimeSlotOpen(false);
  };

  const [selectedIndividualTimeSlot, setSelectedIndividualTimeSlot] =
    useState(null);

  const formatNumber = (number) => {
    if (parseFloat(number) !== 0) {
      const formattedNumber = parseFloat(number).toFixed(0);
      return formattedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return number.toString();
    }
  };

  const [recordStatus, setRecordStatus] = useState(booking?.record_status);

  const handleDateChange = (date) => {
    const formattedDate = formatDate(date);
    setFormData((prevState) => ({
      ...prevState,
      bookingDate: formattedDate,
    }));
    setIsDirty(true);
  };

  // Status change dropdown //
  const [isStatusOpenTwo, setIsStatusOpenTwo] = useState(false);
  const [selectedStatusTwo, setSelectedStatusTwo] = useState(null);
  const [displayedStatusTwo, setDisplayedStatusTwo] = useState("Status");
  const [statusChanged, setStatusChanged] = useState(false);

  const getStatusOptions = (recordStatus, bookingDate) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const bookingDateObj = new Date(bookingDate);
    bookingDateObj.setHours(0, 0, 0, 0);

    if (recordStatus === 5 || recordStatus === 1) {
      if (bookingDateObj >= today) {
        return [
          { value: 2, label: "Cancelled" },
          { value: 4, label: "Ongoing" },
        ];
      } else {
        return [
          { value: 2, label: "Cancelled" },
          { value: 3, label: "Completed" },
        ];
      }
    } else if (recordStatus === 4) {
      return [{ value: 3, label: "Completed" }];
    } else {
      return [{ value: 0, label: "Custom Status" }];
    }
  };

  // Example usage in your component
  const statusOptionsTwo = getStatusOptions(recordStatus, formData.bookingDate);

  useEffect(() => {
    if (recordStatus === 5 || recordStatus === 1) {
      setSelectedStatusTwo(5);
      setDisplayedStatusTwo("Upcoming");
    } else if (recordStatus === 4) {
      setSelectedStatusTwo(4);
      setDisplayedStatusTwo("Ongoing");
    } else {
      setSelectedStatusTwo(null);
      setDisplayedStatusTwo("Status");
    }
  }, [recordStatus]);

  const handleSelectedStatusTwo = (status) => {
    if (status === null) {
      setSelectedStatusTwo(null);
      setDisplayedStatusTwo("Status");
      setIsStatusOpenTwo(false);
    } else {
      setSelectedStatusTwo(status);
      const selectedOption = statusOptionsTwo.find(
        (option) => option.value === status
      );
      if (selectedOption) {
        setDisplayedStatusTwo(selectedOption.label);
        setIsDirty(true);
        setStatusChanged(true);
      } else {
        setDisplayedStatusTwo("Status");
      }
      setIsStatusOpenTwo(false);
    }
  };

  const isPastDate = (selectedDate) => {
    const currentDate = new Date();
    const selectedDateObj = new Date(selectedDate);
    currentDate.setHours(0, 0, 0, 0);
    selectedDateObj.setHours(0, 0, 0, 0);
    return selectedDateObj < currentDate;
  };

  const blockedDays = [];

  const renderCategoryItems = (category, categoryItems) => {
    return (
      <div key={category}>
        <div className="menuCategoryHeading">
          {" "}
          <div>{category}</div> <div>Qty</div>
        </div>
        {categoryItems.map((item) => (
          <div key={item.fnb_item_code} className="menuItemNameWrapper">
            <div>
              <div>
                {item.fnb_type === "NON-VEG" ? (
                  <svg
                    width="13"
                    height="13"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="15"
                      height="15"
                      rx="1.5"
                      stroke="#963415"
                    />
                    <path
                      d="M4.38833 12C4.20992 12 4.09388 11.8122 4.17367 11.6527L7.78534 4.42932C7.87378 4.25243 8.12622 4.25243 8.21466 4.42933L11.8263 11.6527C11.9061 11.8122 11.7901 12 11.6117 12H4.38833Z"
                      fill="#963415"
                    />
                  </svg>
                ) : (
                  <svg
                    width="13"
                    height="13"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="15"
                      height="15"
                      rx="1.5"
                      stroke="#009600"
                    />
                    <circle cx="8" cy="8" r="4" fill="#009600" />
                  </svg>
                )}
              </div>
              <div>{item.fnb_item_name}</div>
            </div>
            <div>x5</div>
          </div>
        ))}
      </div>
    );
  };

  const renderMenu = (menuData, categoryType) => {
    if (!menuData || Object.keys(menuData).length === 0) {
      return null;
    }

    const categories = Object.keys(menuData).map((categoryCode) => {
      const category = booking?.package_categories?.package_categories?.find(
        (cat) => cat.fnb_category_code === categoryCode
      );
      return {
        categoryName: category?.fnb_category || "Unknown Category",
        items: menuData[categoryCode],
      };
    });

    return (
      <div className="w-full mt-3 rounded-2xl bg-white">
        {categories.map((cat) =>
          renderCategoryItems(cat.categoryName, cat.items)
        )}
      </div>
    );
  };

  const renderAddOnsMenu = () => {
    if (
      !booking?.addon_details?.AddOntemSelected ||
      Object.keys(booking?.addon_details?.AddOntemSelected).length === 0
    ) {
      return null;
    }

    return (
      <div className="fandbmenuDesktop">
        <div className="w-full">
          <div className="w-full rounded-2xl bg-white">
            {Object.keys(booking?.addon_details?.AddOntemSelected).map(
              (category) => {
                const categoryData =
                  booking?.addon_details?.AddOn_package_categories.find(
                    (cat) => cat.addon_category_code === category
                  );

                return (
                  <div key={category} className="menuCategoryWrapper">
                    {/* Category Heading */}
                    <div className="menuCategoryHeading">
                      <div>
                        {categoryData?.addon_category || "Unknown Category"}
                      </div>{" "}
                      <div>Qty</div>
                    </div>

                    {/* Category Items */}
                    {booking?.addon_details?.AddOntemSelected[category].map(
                      (item) => (
                        <div
                          key={item.addon_item_code}
                          className="menuItemNameWrapper"
                        >
                          <div>
                            <div>
                              {/* Veg/Non-Veg Icon */}
                              {item.addon_type === "NON-VEG" ? (
                                <svg
                                  width="13"
                                  height="13"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    x="0.5"
                                    y="0.5"
                                    width="15"
                                    height="15"
                                    rx="1.5"
                                    stroke="#963415"
                                  />
                                  <path
                                    d="M4.38833 12C4.20992 12 4.09388 11.8122 4.17367 11.6527L7.78534 4.42932C7.87378 4.25243 8.12622 4.25243 8.21466 4.42933L11.8263 11.6527C11.9061 11.8122 11.7901 12 11.6117 12H4.38833Z"
                                    fill="#963415"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  width="13"
                                  height="13"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    x="0.5"
                                    y="0.5"
                                    width="15"
                                    height="15"
                                    rx="1.5"
                                    stroke="#009600"
                                  />
                                  <circle cx="8" cy="8" r="4" fill="#009600" />
                                </svg>
                              )}
                            </div>

                            <div>{item.addon_item_name}</div>
                          </div>

                          <div>x {item.quantity}</div>
                        </div>
                      )
                    )}
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    );
  };

  function formatAmount(amount) {
    const num = parseFloat(amount);
    const hasDecimals = num % 1 !== 0;

    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: hasDecimals ? 2 : 0,
      maximumFractionDigits: 2,
    });

    return formatter.format(num);
  }
  const isBookingValid =
    currentSection === "bookings" &&
    (booking.record_status === 1 || booking.record_status === 5);
  const isDashboardValid = currentSection === "dashboard";
  const isButtonVisible =
    isBookingValid || booking?.record_status === 4 || isDashboardValid;

  // Pricing Logic

  const [price, setPrice] = useState(booking?.total_booking_amt);
  const [moneyToBeSaved, setMoneyToBeSaved] = useState(
    booking?.total_discount_amt
  );
  const [serviceFee, setServiceFee] = useState(booking?.total_tax_amt);
  const [addOnAmount, setAddOnAmount] = useState(booking?.total_addon_amt || 0);
  const [grandTotal, setGrandTotal] = useState(booking?.gross_booking_amt);
  const initialPrice = booking?.bookingPerPersonCostWithoutDiscount;
  const [couponAmt, setCouponAmt] = useState(
    booking?.total_coupon_amt > 0 ? parseFloat(booking?.total_coupon_amt) : 0
  );

  const [items, setItems] = useState([
    {
      id: 1,
      itemName: "",
      pricePerUnit: "",
      units: "",
      total: "",
      isFirstRow: true,
    },
  ]);

  useEffect(() => {
    const totalCost =
      booking?.package.package_type === "group"
        ? initialPrice
        : initialPrice * numberOfGuests;

    let discount = 0;
    const numericAddOnAmount = parseFloat(addOnAmount) || 0;
    const discountSlab = booking?.packageSlab.find(
      (slab) => numberOfGuests >= slab.min_pax && numberOfGuests <= slab.max_pax
    );

    if (discountSlab && discountSlab.discount_price > 0) {
      switch (discountSlab.discount_type) {
        case "fixed_abs":
          discount = parseFloat(discountSlab.discount_price);
          break;
        case "percent_abs":
          discount =
            (totalCost * parseFloat(discountSlab.discount_price)) / 100;
          break;
        case "guest_abs":
          discount = parseFloat(discountSlab.discount_price) * numberOfGuests;
          break;
        case "guest_percent":
          discount =
            ((initialPrice * parseFloat(discountSlab.discount_price)) / 100) *
            numberOfGuests;
          break;
        default:
          discount = 0;
          break;
      }
    }

    const finalTotalCost = totalCost + numericAddOnAmount;
    const updatedServiceFee = finalTotalCost > 0 ? finalTotalCost * 0.125 : 0;
    const finalDiscountedPrice = finalTotalCost - discount;

    const effectiveCouponAmt = couponAmt > 0 ? couponAmt : 0;
    const calculatedGrandTotal =
      finalDiscountedPrice + updatedServiceFee - effectiveCouponAmt;

    setPrice(totalCost);
    setServiceFee(updatedServiceFee);
    setMoneyToBeSaved(discount);
    setGrandTotal(calculatedGrandTotal);
  }, [addOnAmount, numberOfGuests, couponAmt, booking, initialPrice]);

  const handleAddItemAndTotal = (itemId) => {
    const newItem = {
      id: Date.now(),
      itemName: "",
      pricePerUnit: "",
      units: "",
      total: "",
      isFirstRow: true,
    };

    setItems((prevItems) => {
      const updatedItems = prevItems.map((item) => {
        if (item.id === itemId && item.total) {
          const itemTotal = parseFloat(item.total) || 0;
          setAddOnAmount((prevAmount) => {
            const parsedPrevAmount = parseFloat(prevAmount) || 0;
            return parsedPrevAmount + itemTotal;
          });

          return { ...item, isFirstRow: false };
        }
        return item;
      });

      return [newItem, ...updatedItems];
    });
  };

  const handleDeleteItem = (id) => {
    const itemToDelete = items.find((item) => item.id === id);

    if (itemToDelete && itemToDelete.total) {
      const itemTotal = parseFloat(itemToDelete.total) || 0;
      setAddOnAmount((prevAmount) => {
        const parsedPrevAmount = parseFloat(prevAmount) || 0;
        return parsedPrevAmount - itemTotal;
      });
    }

    setItems(items.filter((item) => item.id !== id));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setIsDirty(true);
    if (name === "bookingTotalGuest") {
      setNumberOfGuests(value);

      if (value === "") {
        setErrorMessage("");
      } else {
        const newValue = parseInt(value, 10);
        if (isNaN(newValue)) {
          setErrorMessage("Please enter a valid number");
        } else if (
          newValue < booking.package[0].min_pax ||
          newValue > booking.package[0].max_pax
        ) {
          setErrorMessage(
            `Group size must be between ${booking.package[0].min_pax} and ${booking.package[0].max_pax}`
          );
        } else {
          setErrorMessage("");
        }
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setErrorMessage("");
    }
  };
  // console.log(isDirty);

  // console.log("price", price);
  // console.log("addOnAmount", addOnAmount);
  // console.log("serviceFee", serviceFee);
  // console.log("moneyToBeSaved", moneyToBeSaved);
  // console.log("grandTotal", grandTotal);

  const handleBackToBookings = () => {
    navigate("/Bookings", {
      state: {
        selectedSection: selectedSection,
        tabs: tabsState,
      },
    });
  };

  const handleBackToClientDetails = () => {
    navigate("/ClientsDetail", {
      state: {
        client,
      },
    });
  };

  const getBreadcrumbItems = () => {
    if (currentSection === "bookings") {
      return [
        {
          title: (
            <span
              onClick={handleBackToBookings}
              style={{
                color: "#7C7C80",
                fontFamily: "Inter",
                fontSize: "0.75rem",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                cursor: "pointer",
              }}
            >
              Booking
            </span>
          ),
        },
        {
          title: (
            <span
              style={{
                color: "#4A6AFE",
                fontFamily: "Inter",
                fontSize: "0.75rem",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
              }}
            >
              Reservation details
            </span>
          ),
        },
      ];
    }

    if (currentSection === "dashboard") {
      return [
        {
          title: (
            <span
              onClick={() => (window.location.href = "/Dashboard")}
              style={{
                color: "#7C7C80",
                fontFamily: "Inter",
                fontSize: "0.75rem",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                cursor: "pointer",
              }}
            >
              Dashboard
            </span>
          ),
        },
        {
          title: (
            <span
              style={{
                color: "#4A6AFE",
                fontFamily: "Inter",
                fontSize: "0.75rem",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
              }}
            >
              Reservation details
            </span>
          ),
        },
      ];
    }

    if (currentSection === "ClientDetail") {
      return [
        {
          title: (
            <span
              onClick={() => (window.location.href = "/Clients")}
              style={{
                color: "#7C7C80",
                fontFamily: "Inter",
                fontSize: "0.75rem",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                cursor: "pointer",
              }}
            >
              Clients
            </span>
          ),
        },
        {
          title: (
            <span
              onClick={handleBackToClientDetails}
              style={{
                color: "#7C7C80",
                fontFamily: "Inter",
                fontSize: "0.75rem",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                cursor: "pointer",
              }}
            >
              Clients Profile
            </span>
          ),
        },
        {
          title: (
            <span
              style={{
                color: "#4A6AFE",
                fontFamily: "Inter",
                fontSize: "0.75rem",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
              }}
            >
              Reservation details
            </span>
          ),
        },
      ];
    }

    return [];
  };

  if (!booking) {
    return <div>No booking details available.</div>;
  }
  return (
    <>
      <div className="">
        <Navbar />

        {/* Heading */}
        <div class="w-full pl-5 pr-5 pt-4 items-center justify-between inline-flex">
          <div class="text-neutral-800 text-2xl font-medium font-['Inter']">
            Reservation Details
          </div>
        </div>

        {/* tab bar  */}
        <div className="w-full pl-6 pr-5 pt-2 pb-2 items-center justify-between inline-flex">
          <Breadcrumb separator=">" items={getBreadcrumbItems()} />
        </div>

        {/* Clients data */}

        <div
          className="bookingData bg-lightBlue px-5 pb-10 pt-8"
          style={{ minHeight: "80vh" }}
        >
          {/* booking section */}
          <div className="bookingDetail">
            <div>
              <div className="leftBookingDetail">
                <h2>Booking ID #{booking.reservation_code}</h2>
                <h1>
                  {formData.bookerFirstName} {formData.bookerLastName}
                </h1>
              </div>

              <div className="rightBookingDetail">
                <div className="assignDetailBox">
                  {" "}
                  <Select
                    showSearch
                    mode="single"
                    style={{
                      width: "100%",
                      // height: 45,
                      // border: "1px solid #C5C5C5",
                      // borderRadius: "0.25rem",
                    }}
                    placeholder="Assign"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={spocOptions}
                    value={selectedSpoc}
                    onChange={handleSPOCNAMEChange}
                    disabled={
                      (recordStatus !== 1 &&
                        recordStatus !== 0 &&
                        recordStatus !== 5) ||
                      isPastDate(formData?.bookingDate)
                    }
                  />
                </div>
                <div className="statusDetailBox">
                  {" "}
                  {recordStatus === 1 ||
                  recordStatus === 4 ||
                  recordStatus === 5 ? (
                    <div
                      className="flex items-center w-full"
                      onClick={() => setIsStatusOpenTwo(!isStatusOpenTwo)}
                    >
                      <p className="pl-3">Status</p>
                      <div className="pl-2 flex items-center">:</div>
                      <div
                        className=" w-[71%] relative py-[0.18rem] pl-4"
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          className={`w-full ${
                            selectedStatusTwo ? "active" : ""
                          }`}
                        >
                          <div className="flex justify-between gap-y-3">
                            <div>
                              <div className="Time-Value ">
                                {displayedStatusTwo === "Upcoming" && (
                                  <div
                                    className="bg-approvedBG rounded-3xl py-1 pl-4 pr-3 flex gap-1"
                                    style={{ alignItems: "center" }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="5"
                                      height="6"
                                      viewBox="0 0 5 6"
                                      fill="none"
                                    >
                                      <circle
                                        cx="2.5"
                                        cy="3"
                                        r="2.5"
                                        fill="#009600"
                                      />
                                    </svg>
                                    <span className="text-approvedText">
                                      Upcoming
                                    </span>
                                  </div>
                                )}
                                {displayedStatusTwo === "Ongoing" && (
                                  <div
                                    className="bg-checkInBG rounded-3xl py-1 pl-4 pr-3 flex gap-1"
                                    style={{ alignItems: "center" }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="5"
                                      height="6"
                                      viewBox="0 0 5 6"
                                      fill="none"
                                    >
                                      <circle
                                        cx="2.5"
                                        cy="3"
                                        r="2.5"
                                        fill="#7389DE"
                                      />
                                    </svg>
                                    <span className="text-checkInText">
                                      Ongoing
                                    </span>
                                  </div>
                                )}
                                {displayedStatusTwo === "Completed" && (
                                  <div
                                    className="bg-checkOutBG rounded-3xl py-1 pl-4 pr-3 flex gap-1"
                                    style={{ alignItems: "center" }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="5"
                                      height="6"
                                      viewBox="0 0 5 6"
                                      fill="none"
                                    >
                                      <circle
                                        cx="2.5"
                                        cy="3"
                                        r="2.5"
                                        fill="#077E8C"
                                      />
                                    </svg>
                                    <span className="text-checkOutText">
                                      Completed
                                    </span>
                                  </div>
                                )}
                                {displayedStatusTwo === "Cancelled" && (
                                  <div
                                    className="bg-cancelledBG rounded-3xl py-1 pl-4 pr-3 flex gap-1"
                                    style={{ alignItems: "center" }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="5"
                                      height="6"
                                      viewBox="0 0 5 6"
                                      fill="none"
                                    >
                                      <circle
                                        cx="2.5"
                                        cy="3"
                                        r="2.5"
                                        fill="#F44336"
                                      />
                                    </svg>
                                    <span className="text-cancelledText">
                                      Cancelled
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="flex items-center">
                              <ChevronDownIcon
                                className={`chevron-icon ${
                                  isStatusOpenTwo ? "up" : "down"
                                }`}
                                style={{
                                  transform: isStatusOpenTwo
                                    ? "rotate(180deg)"
                                    : "none",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {isStatusOpenTwo && (
                          <div className="dropdown-packageOptions-slideOver">
                            {statusOptionsTwo.map((option) => (
                              <div
                                key={option.value}
                                style={{ borderRadius: "0.25rem" }}
                                className={`dropdown-option-slideover flex ${
                                  selectedStatusTwo === option.value
                                    ? "selected"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleSelectedStatusTwo(option.value)
                                }
                              >
                                {option.label === "Upcoming" && (
                                  <div
                                    className="bg-approvedBG rounded-3xl py-1 pl-4 pr-3 flex gap-1"
                                    style={{ alignItems: "center" }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="5"
                                      height="6"
                                      viewBox="0 0 5 6"
                                      fill="none"
                                    >
                                      <circle
                                        cx="2.5"
                                        cy="3"
                                        r="2.5"
                                        fill="#009600"
                                      />
                                    </svg>
                                    <span className="text-approvedText">
                                      Upcoming
                                    </span>
                                  </div>
                                )}

                                {option.label === "Ongoing" && (
                                  <div
                                    className="bg-checkInBG rounded-3xl py-1 pl-4 pr-3 flex gap-1"
                                    style={{ alignItems: "center" }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="5"
                                      height="6"
                                      viewBox="0 0 5 6"
                                      fill="none"
                                    >
                                      <circle
                                        cx="2.5"
                                        cy="3"
                                        r="2.5"
                                        fill="#7389DE"
                                      />
                                    </svg>
                                    <span className="text-checkInText">
                                      Ongoing
                                    </span>
                                  </div>
                                )}
                                {option.label === "Completed" && (
                                  <div
                                    className="bg-checkOutBG rounded-3xl py-1 pl-4 pr-3 flex gap-1"
                                    style={{ alignItems: "center" }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="5"
                                      height="6"
                                      viewBox="0 0 5 6"
                                      fill="none"
                                    >
                                      <circle
                                        cx="2.5"
                                        cy="3"
                                        r="2.5"
                                        fill="#077E8C"
                                      />
                                    </svg>
                                    <span className="text-checkOutText">
                                      Completed
                                    </span>
                                  </div>
                                )}
                                {option.label === "Cancelled" && (
                                  <div
                                    className="bg-cancelledBG rounded-3xl py-1 pl-4 pr-3 flex gap-1"
                                    style={{ alignItems: "center" }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="5"
                                      height="6"
                                      viewBox="0 0 5 6"
                                      fill="none"
                                    >
                                      <circle
                                        cx="2.5"
                                        cy="3"
                                        r="2.5"
                                        fill="#F44336"
                                      />
                                    </svg>
                                    <span className="text-cancelledText">
                                      Cancelled
                                    </span>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center w-[75%]">
                      <p className="pl-3">Status</p>
                      <div className="pl-2 flex items-center">:</div>
                      <div
                        style={{ cursor: "pointer" }}
                        className="block w-[71%] relative py-[0.18rem] pl-4 text-black focus:ring-groupleBlue focus:bg-white sm:text-sm sm:leading-6"
                      >
                        <div>{renderStatus()}</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="navline">
              <nav className="flex space-x-8" aria-label="Tabs">
                {tabs.map((tab) => (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a
                    key={tab.name}
                    onClick={() => handleNavItemClick(tab.name)}
                    className={classNames(
                      tab.current
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700",
                      "flex whitespace-nowrap border-b-2 py-3 px-1 text-sm font-medium"
                    )}
                    aria-current={tab.current ? "page" : undefined}
                    style={{ alignItems: "center" }}
                  >
                    {tab.name}
                  </a>
                ))}
              </nav>
            </div>

            {tabs.find((tab) => tab.current)?.name === "Details" && (
              <>
                <div className="belowBookingDetail">
                  <h3>Client details</h3>

                  <div className="inputsBookingDetail">
                    <div>
                      <h2>First name</h2>
                      <input
                        type="text"
                        name="bookerFirstName"
                        id="bookerFirstName"
                        value={formData.bookerFirstName}
                        onChange={handleInputChange}
                        readOnly={
                          (recordStatus !== 1 && recordStatus !== 5) ||
                          isPastDate(formData.bookingDate)
                        }
                        className={`date-input block w-full rounded-lg border-0 py-2.5 px-3 text-black shadow-sm ring-1 ring-inset ring-white/10 ${
                          (recordStatus !== 1 && recordStatus !== 5) ||
                          isPastDate(formData.bookingDate)
                            ? "pointer-events-none focus:ring-transparent"
                            : "focus:ring-groupleBlue"
                        } focus:ring-1 cursor-pointer focus:bg-white sm:text-sm sm:leading-6`}
                      />
                    </div>

                    <div>
                      <h2>Last name</h2>
                      <input
                        type="text"
                        name="bookerLastName"
                        id="bookerLastName"
                        value={formData.bookerLastName}
                        onChange={handleInputChange}
                        readOnly={
                          (recordStatus !== 1 && recordStatus !== 5) ||
                          isPastDate(formData.bookingDate)
                        }
                        className={`date-input block w-full rounded-lg border-0 py-2.5 px-3 text-black shadow-sm ring-1 ring-inset ring-white/10 ${
                          (recordStatus !== 1 && recordStatus !== 5) ||
                          isPastDate(formData.bookingDate)
                            ? "pointer-events-none focus:ring-transparent"
                            : "focus:ring-groupleBlue"
                        } focus:ring-1 cursor-pointer focus:bg-white sm:text-sm sm:leading-6`}
                      />
                    </div>
                  </div>

                  <div className="inputsBookingDetail">
                    <div>
                      <h2>Email</h2>
                      <input
                        type="email"
                        name="bookerEmail"
                        id="bookerEmail"
                        value={formData.bookerEmail}
                        onChange={handleInputChange}
                        readOnly={
                          (recordStatus !== 1 && recordStatus !== 5) ||
                          isPastDate(formData.bookingDate)
                        }
                        className={`date-input block w-full rounded-lg border-0 py-2.5 px-3 text-black shadow-sm ring-1 ring-inset ring-white/10 ${
                          (recordStatus !== 1 && recordStatus !== 5) ||
                          isPastDate(formData.bookingDate)
                            ? "pointer-events-none focus:ring-transparent"
                            : "focus:ring-groupleBlue"
                        } focus:ring-1 cursor-pointer focus:bg-white sm:text-sm sm:leading-6`}
                      />
                    </div>

                    <div>
                      <h2>Phone number</h2>
                      <input
                        type="number"
                        name="bookerPhone"
                        id="bookerPhone"
                        value={formData.bookerPhone}
                        onChange={handleInputChange}
                        readOnly={
                          (recordStatus !== 1 && recordStatus !== 5) ||
                          isPastDate(formData.bookingDate)
                        }
                        className={`date-input block w-full rounded-lg border-0 py-2.5 px-3 text-black shadow-sm ring-1 ring-inset ring-white/10 ${
                          (recordStatus !== 1 && recordStatus !== 5) ||
                          isPastDate(formData.bookingDate)
                            ? "pointer-events-none focus:ring-transparent"
                            : "focus:ring-groupleBlue"
                        } focus:ring-1 cursor-pointer focus:bg-white sm:text-sm sm:leading-6`}
                      />
                    </div>
                  </div>

                  <div className="inputsBookingDetail">
                    <div>
                      <h2>Reservation notes</h2>
                      <textarea
                        name="bookingNotes"
                        id="bookingNotes"
                        value={formData.bookingNotes}
                        onChange={handleInputChange}
                        readOnly={
                          (recordStatus !== 1 && recordStatus !== 5) ||
                          isPastDate(formData.bookingDate)
                        }
                        className={`date-input block w-full rounded-lg border border-[#c5c5c5] py-2.5 px-3 text-black shadow-sm ring-1 ring-inset ring-white/10 ${
                          (recordStatus !== 1 && recordStatus !== 5) ||
                          isPastDate(formData.bookingDate)
                            ? "pointer-events-none focus:ring-transparent"
                            : "focus:ring-groupleBlue"
                        } focus:ring-1 cursor-pointer focus:bg-white sm:text-sm sm:leading-6`}
                      />
                    </div>
                  </div>
                </div>

                <div className="belowBookingDetail">
                  <h3>Booking details</h3>

                  <div className="inputsBookingDetail">
                    <div>
                      <h2>Booking Date</h2>
                      <DatePicker
                        placeholder="Select a date"
                        oneTap
                        format="dd-MMM-yyyy"
                        size="lg"
                        value={new Date(formData.bookingDate)}
                        onChange={handleDateChange}
                        renderExtraFooter={() => null}
                        cleanable={false}
                        readOnly={
                          (recordStatus !== 1 && recordStatus !== 5) ||
                          isPastDate(formData.bookingDate)
                        }
                        disabledDate={(date) => {
                          const day = date.getDay();
                          return blockedDays.includes(day);
                        }}
                        className="custom-date-range-picker2 z-50 text-black border border-[#c5c5c5] rounded-[7px]"
                      />
                    </div>

                    <div>
                      <h2>Time Slot</h2>
                      <div
                        className="sort-wrapper-main-two"
                        style={{
                          cursor:
                            (recordStatus !== 1 && recordStatus !== 5) ||
                            isPastDate(formData.bookingDate)
                              ? "not-allowed"
                              : "pointer",
                        }}
                      >
                        <div className="custom-dropdown-two">
                          <div
                            className={`block border border-[#c5c5c5] w-full rounded-lg  py-2.5 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-1 focus:ring-groupleBlue px-3 focus:bg-white sm:text-sm sm:leading-6 ${
                              (recordStatus !== 1 && recordStatus !== 5) ||
                              isPastDate(formData.bookingDate)
                                ? "cursor-not-allowed"
                                : ""
                            } ${selectedTimeSlot ? "active" : ""}`}
                            onClick={() => {
                              if (
                                (recordStatus !== 1 && recordStatus !== 5) ||
                                isPastDate(formData.bookingDate)
                              )
                                return;
                              setIsTimeSlotOpen(!isTimeSlotOpen);
                            }}
                          >
                            {selectedTimeSlot ? (
                              <div className="Time-placeHolder">
                                <div>
                                  <div className="Time-Value">
                                    <>
                                      {formData.bookingSlotStartTime} -{" "}
                                      {formData.bookingSlotEndTime}
                                    </>
                                  </div>
                                </div>
                                <div>
                                  <ChevronDownIcon
                                    className={`chevron-icon ${
                                      isTimeSlotOpen ? "up" : "down"
                                    }`}
                                    style={{
                                      transform: isTimeSlotOpen
                                        ? "rotate(180deg)"
                                        : "none",
                                    }}
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="Time-placeHolder flex justify-between">
                                <div>
                                  <>
                                    {formData.bookingSlotStartTime} -{" "}
                                    {formData.bookingSlotEndTime}
                                  </>
                                </div>
                                <div>
                                  <ChevronDownIcon
                                    className={`chevron-icon ${
                                      isTimeSlotOpen ? "up" : "down"
                                    }`}
                                    style={{
                                      transform: isTimeSlotOpen
                                        ? "rotate(180deg)"
                                        : "none",
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          {isTimeSlotOpen && (
                            <div className="dropdown-packageOptions-timeSlot-two">
                              {filteredTimeSlots.map((option) => (
                                <div
                                  key={option.label}
                                  style={{ borderRadius: "0.25rem" }}
                                  className={`dropdown-packageOption-timeSlot-two flex justify-between hover:bg-customLilac hover:text-groupleBlue ${
                                    selectedTimeSlot === option.label
                                      ? "selected"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleSelectedTimeSlot(option.label)
                                  }
                                >
                                  {option.label}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="inputsBookingDetail">
                    <div>
                      <h2>Group Size</h2>
                      <input
                        type="number"
                        name="bookingTotalGuest"
                        id="bookingTotalGuest"
                        style={{ width: "49%" }}
                        value={numberOfGuests}
                        onChange={handleInputChange}
                        readOnly={
                          (booking.record_status !== 1 &&
                            booking.record_status !== 5) ||
                          isPastDate(formData.bookingDate)
                        }
                        className={`date-input block rounded-lg border-0 py-2.5 px-3 text-black shadow-sm ring-1 ring-inset ring-white/10 ${
                          (recordStatus !== 1 && recordStatus !== 5) ||
                          isPastDate(formData.bookingDate)
                            ? "pointer-events-none focus:ring-transparent"
                            : "focus:ring-groupleBlue"
                        } focus:ring-1 cursor-pointer focus:bg-white sm:text-sm sm:leading-6`}
                      />
                    </div>
                  </div>
                  {errorMessage && (
                    <p className="error-text text-red-500">{errorMessage}</p>
                  )}
                </div>

                <div className="belowBookingDetail">
                  <h3>Package details</h3>

                  <div className="inputsBookingDetail">
                    <div>
                      <h2>Category</h2>
                      <input
                        type="text"
                        value={formData.bookingPackageCategory}
                        readOnly
                      />
                    </div>

                    <div>
                      <h2>Package</h2>
                      <input
                        type="text"
                        value={formData.bookingGrouplePackageCategory}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="inputsBookingDetail">
                    <div>
                      <h2>Package name</h2>
                      <input
                        type="text"
                        style={{ width: "49%" }}
                        value={formData.bookingPackage}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div className="belowBookingDetail">
                  <h3>Invoice details</h3>

                  <div className="inputsBookingDetail">
                    <div>
                      <h2>Company/ Person name</h2>
                      <input
                        type="text"
                        value={formData.bookerCompanyName}
                        readOnly
                      />
                    </div>

                    <div>
                      <h2>VAT number</h2>
                      <input
                        type="text"
                        value={formData.bookerVATNumber}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="inputsBookingDetail">
                    <div>
                      <h2> Street address</h2>
                      <input
                        type="email"
                        value={formData.bookerAddress}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="inputsBookingDetail">
                    <div>
                      <h2>City</h2>
                      <input type="text" value={formData.bookerCity} readOnly />
                    </div>

                    <div>
                      <h2>Postal code</h2>
                      <input
                        type="number"
                        value={formData.bookerPostalCode}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="inputsBookingDetail">
                    <div>
                      <h2>Country</h2>
                      <input
                        type="text"
                        value={formData.bookerCountry}
                        readOnly
                      />
                    </div>

                    <div>
                      <h2>State /Province</h2>
                      <input
                        type="text"
                        value={formData.bookerState}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                {isButtonVisible ? (
                  <div
                    style={{
                      position: "fixed",
                      bottom: "0",
                      left: "18%",
                      width: "60%",
                      backgroundColor: "#fff",
                      zIndex: 100,
                      display: "flex",
                      justifyContent: "end",
                      boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    {isDirty && errorMessage === "" && (
                      <div className="flex flex-shrink-0 justify-center px-4 py-4">
                        <button
                          type="button"
                          className="w-full inline-flex justify-center rounded-md bg-groupleBlue px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                          onClick={handleButtonClick}
                        >
                          Save Reservation
                        </button>
                      </div>
                    )}
                  </div>
                ) : null}

                {booking.record_status === 0 && (
                  <div className="detailsButton">
                    {cancelClicked && (
                      <div>
                        <div className="flex justify-center">
                          <div
                            style={{
                              color: "#B15C52",
                              fontSize: "1rem",
                              fontStyle: "normal",
                              fontWeight: "500",
                            }}
                            className="flex gap-2"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M8.9375 0C7.16983 0 5.44186 0.524175 3.9721 1.50624C2.50233 2.48831 1.35679 3.88415 0.680331 5.51727C0.00387248 7.15038 -0.17312 8.94742 0.171736 10.6811C0.516591 12.4148 1.36781 14.0073 2.61774 15.2573C3.86767 16.5072 5.46018 17.3584 7.19388 17.7033C8.92759 18.0481 10.7246 17.8711 12.3577 17.1947C13.9909 16.5182 15.3867 15.3727 16.3688 13.9029C17.3508 12.4331 17.875 10.7052 17.875 8.9375C17.8725 6.5679 16.9301 4.29606 15.2545 2.6205C13.5789 0.944933 11.3071 0.00250234 8.9375 0ZM8.25 4.8125C8.25 4.63016 8.32244 4.4553 8.45137 4.32636C8.5803 4.19743 8.75517 4.125 8.9375 4.125C9.11984 4.125 9.29471 4.19743 9.42364 4.32636C9.55257 4.4553 9.625 4.63016 9.625 4.8125V9.625C9.625 9.80734 9.55257 9.98221 9.42364 10.1111C9.29471 10.2401 9.11984 10.3125 8.9375 10.3125C8.75517 10.3125 8.5803 10.2401 8.45137 10.1111C8.32244 9.98221 8.25 9.80734 8.25 9.625V4.8125ZM8.9375 13.75C8.73354 13.75 8.53416 13.6895 8.36457 13.5762C8.19498 13.4629 8.06281 13.3018 7.98475 13.1134C7.9067 12.925 7.88628 12.7176 7.92607 12.5176C7.96586 12.3175 8.06408 12.1338 8.2083 11.9895C8.35252 11.8453 8.53627 11.7471 8.73632 11.7073C8.93636 11.6675 9.14371 11.6879 9.33215 11.766C9.52058 11.8441 9.68164 11.9762 9.79496 12.1458C9.90827 12.3154 9.96875 12.5148 9.96875 12.7188C9.96875 12.9923 9.8601 13.2546 9.66671 13.448C9.47331 13.6413 9.21101 13.75 8.9375 13.75Z"
                                fill="#B15C52"
                              />
                            </svg>
                            <div>
                              Are you sure you want to cancel this reservation?
                              This action cannot be undone.
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-end gap-4 pt-2">
                          <button
                            type="button"
                            className="cancelDetailButton"
                            onClick={handleButtonClick}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="approveDetailButton"
                            onClick={handleButtonClick}
                          >
                            Approve
                          </button>
                        </div>
                      </div>
                    )}
                    {!cancelClicked && (
                      <>
                        <button
                          type="button"
                          className="cancelDetailButton"
                          onClick={handleButtonClick}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="approveDetailButton"
                          onClick={handleButtonClick}
                        >
                          Approve
                        </button>
                      </>
                    )}
                  </div>
                )}
              </>
            )}

            {tabs.find((tab) => tab.current)?.name === "Food Menu" && (
              <>
                {/* Food menu */}
                {booking?.foodItemSelected?.food &&
                  Object.keys(booking?.foodItemSelected?.food).length > 0 && (
                    <div className="w-full rounded-2xl bg-white">
                      {renderMenu(booking?.foodItemSelected.food, "F")}
                    </div>
                  )}
              </>
            )}

            {tabs.find((tab) => tab.current)?.name === "Beverage Menu" && (
              <>
                {/* Beverage menu */}
                {booking?.foodItemSelected?.beverage &&
                  Object.keys(booking?.foodItemSelected?.beverage).length >
                    0 && (
                    <div className="w-full rounded-2xl bg-white">
                      {renderMenu(booking?.foodItemSelected.beverage, "B")}
                    </div>
                  )}
              </>
            )}

            {tabs.find((tab) => tab.current)?.name === "Add Ons" && (
              <div className="belowBookingDetail">
                {booking?.addon_details?.AddOntemSelected &&
                  Object.values(booking?.addon_details?.AddOntemSelected).some(
                    (items) => items.length > 0
                  ) &&
                  renderAddOnsMenu()}
              </div>
            )}

            {tabs.find((tab) => tab.current)?.name === "Payment" && (
              <div className="belowBookingDetail">
                <h3>Add Items</h3>

                {items.map((item, index) => (
                  <div className="addItemLine" key={item.id}>
                    <div>
                      <h2>Item name</h2>
                      <input
                        type="text"
                        value={item.itemName}
                        readOnly={!item.isFirstRow}
                        onChange={(e) =>
                          setItems(
                            items.map((i) =>
                              i.id === item.id
                                ? { ...i, itemName: e.target.value }
                                : i
                            )
                          )
                        }
                      />
                    </div>

                    <div>
                      <h2>Price/Unit</h2>
                      <input
                        type="text"
                        value={item.pricePerUnit}
                        readOnly={!item.isFirstRow}
                        onChange={(e) =>
                          setItems(
                            items.map((i) =>
                              i.id === item.id
                                ? {
                                    ...i,
                                    pricePerUnit: e.target.value,
                                    total:
                                      e.target.value && i.units
                                        ? e.target.value * i.units
                                        : "",
                                  }
                                : i
                            )
                          )
                        }
                      />
                    </div>

                    <div>
                      <h2>Units</h2>
                      <input
                        type="text"
                        value={item.units}
                        readOnly={!item.isFirstRow}
                        onChange={(e) =>
                          setItems(
                            items.map((i) =>
                              i.id === item.id
                                ? {
                                    ...i,
                                    units: e.target.value,
                                    total:
                                      e.target.value && i.pricePerUnit
                                        ? e.target.value * i.pricePerUnit
                                        : "",
                                  }
                                : i
                            )
                          )
                        }
                      />
                    </div>

                    <div>
                      <h2>Total</h2>
                      <input
                        type="text"
                        value={item.total}
                        style={{ background: "#F0F1F3" }}
                        readOnly
                      />
                    </div>

                    <div className="buttonSection">
                      {item.isFirstRow ? (
                        <div>
                          <button
                            onClick={() => {
                              handleAddItemAndTotal(item.id);
                            }}
                            disabled={!item.total}
                          >
                            Add to final bill
                          </button>
                        </div>
                      ) : (
                        <div
                          className="pb-3"
                          onClick={() => handleDeleteItem(item.id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M15.1875 3.375H2.8125C2.58872 3.375 2.37411 3.46389 2.21588 3.62213C2.05764 3.78036 1.96875 3.99497 1.96875 4.21875C1.96875 4.44253 2.05764 4.65714 2.21588 4.81537C2.37411 4.9736 2.58872 5.0625 2.8125 5.0625H3.09375V14.625C3.09375 14.998 3.24191 15.3556 3.50563 15.6194C3.76935 15.8831 4.12704 16.0312 4.5 16.0312H13.5C13.873 16.0312 14.2306 15.8831 14.4944 15.6194C14.7581 15.3556 14.9062 14.998 14.9062 14.625V5.0625H15.1875C15.4113 5.0625 15.6259 4.9736 15.7841 4.81537C15.9424 4.65714 16.0312 4.44253 16.0312 4.21875C16.0312 3.99497 15.9424 3.78036 15.7841 3.62213C15.6259 3.46389 15.4113 3.375 15.1875 3.375ZM13.2188 14.3437H4.78125V5.0625H13.2188V14.3437ZM5.34375 1.40625C5.34375 1.18247 5.43264 0.967862 5.59088 0.809629C5.74911 0.651395 5.96372 0.5625 6.1875 0.5625H11.8125C12.0363 0.5625 12.2509 0.651395 12.4091 0.809629C12.5674 0.967862 12.6562 1.18247 12.6562 1.40625C12.6562 1.63003 12.5674 1.84464 12.4091 2.00287C12.2509 2.1611 12.0363 2.25 11.8125 2.25H6.1875C5.96372 2.25 5.74911 2.1611 5.59088 2.00287C5.43264 1.84464 5.34375 1.63003 5.34375 1.40625Z"
                              fill="#7C7C80"
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                  </div>
                ))}

                <div className="belowBookingDetail">
                  <h3>Invoice details</h3>

                  <div className="inputsBookingDetail">
                    <div>
                      <h2>Company/ Person name</h2>
                      <input
                        type="text"
                        value={formData.bookerCompanyName}
                        readOnly
                      />
                    </div>

                    <div>
                      <h2>VAT number</h2>
                      <input
                        type="text"
                        value={formData.bookerVATNumber}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="inputsBookingDetail">
                    <div>
                      <h2> Street address</h2>
                      <input
                        type="email"
                        value={formData.bookerAddress}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="inputsBookingDetail">
                    <div>
                      <h2>City</h2>
                      <input type="text" value={formData.bookerCity} readOnly />
                    </div>

                    <div>
                      <h2>Postal code</h2>
                      <input
                        type="number"
                        value={formData.bookerPostalCode}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="inputsBookingDetail">
                    <div>
                      <h2>Country</h2>
                      <input
                        type="text"
                        value={formData.bookerCountry}
                        readOnly
                      />
                    </div>

                    <div>
                      <h2>State /Province</h2>
                      <input
                        type="text"
                        value={formData.bookerState}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <h4>Payment Summary</h4>

                {price > 0 && (
                  <div className="paymentSummaryPara">
                    <div>
                      <h5>
                        {booking.bookingCurrency}{" "}
                        {formatAmount(
                          booking.bookingPerPersonCostWithoutDiscount
                        )}{" "}
                        × {numberOfGuests}
                      </h5>
                    </div>
                    <div>
                      <h5>
                        {booking.bookingCurrency} {formatAmount(price)}
                      </h5>
                    </div>
                  </div>
                )}

                {addOnAmount > 0 && (
                  <div className="paymentSummaryPara">
                    <div>
                      <h5>Add ons</h5>
                    </div>
                    <div>
                      <h5>
                        {booking.bookingCurrency} {formatAmount(addOnAmount)}
                      </h5>
                    </div>
                  </div>
                )}

                {serviceFee > 0 && (
                  <div className="paymentSummaryPara">
                    <div>
                      <h5>Service fee</h5>
                    </div>
                    <div>
                      <h5>
                        {booking.bookingCurrency} {formatAmount(serviceFee)}
                      </h5>
                    </div>
                  </div>
                )}

                {moneyToBeSaved > 0 && (
                  <div className="paymentSummaryPara">
                    <div>
                      <h5 style={{ color: "#4A6AFE" }}>Discount</h5>
                    </div>
                    <div>
                      <h5 style={{ color: "#4A6AFE" }}>
                        - {booking.bookingCurrency}{" "}
                        {formatAmount(moneyToBeSaved)}
                      </h5>
                    </div>
                  </div>
                )}

                {couponAmt > 0 && (
                  <div className="paymentSummaryPara">
                    <div>
                      <h5 style={{ color: "#4A6AFE" }}>Coupon</h5>
                    </div>
                    <div>
                      <h5 style={{ color: "#4A6AFE" }}>
                        - {booking.bookingCurrency} {formatAmount(couponAmt)}
                      </h5>
                    </div>
                  </div>
                )}

                {booking.gross_booking_amt > 0 && (
                  <div className="paymentSummaryParaHead">
                    <div>
                      <h5>Grand Total</h5>
                    </div>
                    <div>
                      <h5>
                        {booking.bookingCurrency} {formatAmount(grandTotal)}
                      </h5>
                    </div>
                  </div>
                )}

                {/* <div className="paymentSummaryPara">
                  <div>
                    <h5 style={{ color: "#4A6AFE" }}>Advance Payment</h5>
                  </div>
                  <div>
                    <h5 style={{ color: "#4A6AFE" }}>AED 6,000</h5>
                  </div>
                </div>

                <div
                  className="paymentSummaryParaHead"
                  style={{ borderBottom: "none" }}
                >
                  <div>
                    <h5>Amount Due</h5>
                  </div>
                  <div>
                    <h5>AED 6,475</h5>
                  </div>
                </div> */}

                {recordStatus === 1 && booking?.invoiceFilePath && (
                  <div className="invoiceButton">
                    <a
                      href={booking.invoiceFilePath}
                      download
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>Send Invoice</button>
                    </a>
                  </div>
                )}
              </div>
            )}

            {tabs.find((tab) => tab.current)?.name === "Guest list" && (
              <>
                <div className="belowBookingDetail">
                  <div className="overflow-hidden border border-gray-300 rounded-tr-xl rounded-tl-xl h-fit rounded-br-xl rounded-bl-xl">
                    <table className="min-w-full divide-y divide-gray-300 table-fixed">
                      <thead className="bg-gray-50">
                        <tr>
                          {headers.map((header, index) => (
                            <th
                              key={index}
                              scope="col"
                              className="py-2.5 pl-4 text-left text-sm font-normal text-gray-500 sm:pl-6"
                            >
                              {header}
                            </th>
                          ))}
                          <th className="py-2.5 pl-4 text-left text-sm font-normal text-gray-500 sm:pl-6"></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white text-left">
                        {booking?.invitee_list?.invitee_list?.length > 0 ? (
                          booking.invitee_list.invitee_list.map(
                            (row, index) => (
                              <tr
                                key={index}
                                onClick={() => handleRowClick(row)}
                                className="cursor-pointer"
                              >
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {row.invitee_fname} {row.invitee_lname}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {row.invitee_mobile}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  Pizza, garlic bread, soft drinks
                                </td>
                              </tr>
                            )
                          )
                        ) : (
                          <tr>
                            <td
                              colSpan="3"
                              className="whitespace-nowrap py-4 text-sm text-center text-gray-500"
                            >
                              No guests invited yet
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* popup  */}
                {popupVisible && selectedGuest && (
                  <div className="popup-container">
                    <div className="popup">
                      <button className="popup-close" onClick={closePopup}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M7 7L17 17"
                            stroke="black"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7 17L17 7"
                            stroke="black"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                      <h2>Guest Details</h2>
                      <h1>{selectedGuest.name}</h1>
                      <div className="outsidePopupDetails">
                        <div className="insideGuestPopup">
                          <h3>Phone Number</h3>
                          <p>{selectedGuest.phone}</p>
                        </div>{" "}
                        <div className="insideGuestPopup">
                          <h3>Email</h3>
                          <p>marvinmckinney@gmail.com</p>
                        </div>{" "}
                      </div>
                      <div className="insideGuestPopup">
                        <h3>Reservation Notes</h3>
                        <p>{selectedGuest.items}</p>
                      </div>{" "}
                      <div className="navline">
                        <nav className="flex space-x-8" aria-label="Tabs">
                          {tabs2.map((tab) => (
                            <a
                              key={tab.name}
                              onClick={() => handleNavItemClick2(tab.name)}
                              className={classNames(
                                tab.current
                                  ? "border-indigo-500 text-indigo-600"
                                  : "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700",
                                "flex whitespace-nowrap border-b-2 py-3 px-1 text-sm font-medium"
                              )}
                              aria-current={tab.current ? "page" : undefined}
                              style={{ alignItems: "center" }}
                            >
                              {tab.name}
                            </a>
                          ))}
                        </nav>
                      </div>
                      {tabs2.find((tab) => tab.current)?.name ===
                        "Food menu" && (
                        <>
                          {" "}
                          <div className="w-full mt-3 rounded-xl bg-white">
                            <Disclosure>
                              {({ open }) => (
                                <>
                                  <Disclosure.Button
                                    className={`foodMenuDropdownButton flex px-3 py-3 w-full justify-between border border-gray-300 rounded-xl ${
                                      open ? "" : ""
                                    }`}
                                  >
                                    <span className=" text-base font-medium text-gray-950">
                                      4 Appetisers, 4 Main Course, 1 desserts,
                                      Sides
                                    </span>
                                    <ChevronDownIcon
                                      className={`${
                                        open ? "rotate-180 transform" : ""
                                      } h-5 w-5 text-black-500`}
                                    />
                                  </Disclosure.Button>
                                  <Disclosure.Panel className="dropdownMenuWrapper">
                                    4 Appetisers, 4 Main Course, 1 desserts,
                                    Sides
                                  </Disclosure.Panel>
                                </>
                              )}
                            </Disclosure>
                          </div>
                        </>
                      )}
                      {tabs2.find((tab) => tab.current)?.name ===
                        "Beverage menu" && <>hello2</>}
                      {tabs2.find((tab) => tab.current)?.name === "Add ons" && (
                        <>hello3</>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>

          {/* activity section */}
          <div className="activityDetail">Activity</div>
        </div>
      </div>
    </>
  );
};

export default BookingsDetails;
