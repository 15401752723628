import React from 'react';
import loader from "../Images/LOADINGaNIMATION.gif"

const Loader = () => {
  return (
    <div style={overlayStyle}>
    <div style={loaderContainerStyle}>
      <img src={loader} alt="Loading..." style={loaderGifStyle} />
    </div>
  </div>
  );
};


// Styles for Full-Page Overlay
const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
    margin: "0rem",
  };
  
  // Loader Container Style
  const loaderContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };
  
  // Loader GIF Style
  const loaderGifStyle = {
    width: "90px",
    height: "90px",
  };

export default Loader;
